import { faBookmark } from "@fortawesome/free-solid-svg-icons/faBookmark";
import { faBellPlus } from "@fortawesome/pro-solid-svg-icons/faBellPlus";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { setBookmarkedPromise } from "routines/bookmarks";
import {
  followEntityPromise,
  subscribeEntityPromise,
  unfollowEntityPromise,
  unsubscribeEntityPromise,
} from "routines/follow";

import ActionHandler from "components/ActionHandler/ActionHandler";
import ModalConfirmationAction from "components/ActionHandler/ModalConfirmationActionAsync";
import RequestContext from "pages/RequestContext";

import claimActions from "actions/claiming";
import modalActions from "actions/modals";
import ratingActions from "actions/rating";
import { selectSpecificPodcast } from "selectors/podcast";
import getPodcastName from "utils/entity/getPodcastName";
import getSecondsDurationFromTimestamp from "utils/getSecondsDurationFromTimestamp";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useAudioPlayerContext from "hooks/useAudioPlayerContext";
import { useLoggedIn } from "hooks/useLoggedInUser";
import useQuery from "hooks/useQuery";
import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";

import faFollow from "styles/icons/faFollow";

const EntityPageActionHandler = (props) => {
  const { entity, entity_type, getName, pageType } = props;
  const entity_id = entity && entity.get("id");
  const podcast_id =
    entity_type === "episode" && entity && entity.get("podcast_id");

  const { showModal, closeModal } = useActionCreators({
    showModal: modalActions.showModal,
    closeModal: modalActions.closeModal,
  });

  const podcast = useReduxState(
    (state) => selectSpecificPodcast(state, podcast_id),
    [entity_type, podcast_id]
  );

  const entityName = entity && getName && getName(entity);

  const requestContext = useContext(RequestContext);
  const { seekPlayerTo, togglePlaying } = useAudioPlayerContext();

  const isLoggedIn = useLoggedIn();
  const isFollowing =
    isLoggedIn && entity && entity.getIn(["user_data", "follows"]);

  const { openClaimModal, openRatingModal } = useActionCreators({
    openClaimModal: claimActions.openClaimModal,
    openRatingModal: ratingActions.openRatingModal,
  });

  const [query] = useQuery();

  const { rating, review, source, time } = query || {};

  const {
    unsubscribeEntity,
    subscribeEntity,
    unfollowEntity,
    followEntity,
    setBookmarked,
  } = useRoutinePromises({
    unsubscribeEntity: unsubscribeEntityPromise,
    subscribeEntity: subscribeEntityPromise,
    unfollowEntity: unfollowEntityPromise,
    followEntity: followEntityPromise,
    setBookmarked: setBookmarkedPromise,
  });

  const analyticsVariables = useMemo(
    () => ({
      from_action_handler: true,
    }),
    []
  );

  const handleAction = useCallback(
    (action, actionProps = null) =>
      () =>
        action({
          entity_type,
          entity_id,
          analyticsVariables,
          ...(actionProps || {}),
        }),
    [entity_type, entity_id, analyticsVariables]
  );

  const renderActionContent = useCallback(
    // eslint-disable-next-line react/display-name
    (content) => (contentProps) =>
      (
        <ModalConfirmationAction
          title={content.title}
          icon={content.icon}
          onConfirm={content.onConfirm}
          confirmButtonText={content.confirmButtonText}
          successMessage={content.successMessage}
          loadingLabel={content.loadingLabel}
          authFlowProps={content.authFlowProps}
          onClose={contentProps.onClose}
          userMustBeLoggedIn
        />
      ),
    []
  );
  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      entity_type,
      context: "ProLeadForm",
    });
  }, [entity_type]);

  const renderProLeadForm = useCallback(() => {
    sendGAEvent({
      action: "RenderProleadModalForm",
      entity_type: entity_type,
      context: "ProLeadForm",
    });

    showModal("proLeadForm", {
      onDemoRequestSubmitted,
    });
  }, [showModal, entity_type, onDemoRequestSubmitted]);

  const renderModal = useCallback(
    (time) => {
      sendGAEvent({
        action: "Shared Episode with time modal rendered",
        episode_id: entity.get("id"),
        episode_name: entity.get("name"),
        context: "EpisodeAirCheckAutoPlayModal",
      });

      showModal("episodePlayOnRender", {
        title: "Start Playing From",
        episodeId: entity && entity.get("id"),
        episodeName: entity && entity.get("name"),
        audioUrl: entity && entity.get("audio_url"),
        seekPlayerTo,
        togglePlaying,
        closeModal,
        time,
      });
    },
    [closeModal, entity, seekPlayerTo, showModal, togglePlaying]
  );

  const actions = useMemo(
    () =>
      [
        {
          query: "pro",
          loggingInTitle: "Logging you in so you can sign up for Podchaser Pro",
          handler: () => renderProLeadForm(),
        },
        pageType !== "homePage" && {
          query: "subscribe",
          loggingInTitle: `Logging you in so you can follow and get notifications about "${entityName}"`,
          modal: true,
          analyticsParams: {
            entity_type,
            entity_id,
          },
          renderContent: renderActionContent({
            title: `Would you like to ${
              isFollowing ? "" : "follow and "
            }get notifications about "${entityName}"?`,
            icon: faBellPlus,
            onConfirm: handleAction(subscribeEntity),
            confirmButtonText: "Subscribe",
            successMessage: "Successfully subscribed to notifications",
            loadingLabel: "Subscribing",
            authFlowProps: {
              actionType: "Subscribe",
              entityTitle: `for email notifications from "${entityName}"`,
              entityAction: "action",
            },
          }),
        },
        pageType !== "homePage" && {
          query: "unsubscribe",
          loggingInTitle: `Logging you in so you stop email notifications from "${entityName}"`,
          modal: true,
          renderContent: renderActionContent({
            title: `Would you like to stop email notifications from "${entityName}"?`,
            icon: faBellPlus,
            onConfirm: handleAction(unsubscribeEntity),
            confirmButtonText: "Stop notifications",
            successMessage:
              "Successfully unsubscribed from email notifications",
            loadingLabel: "Unsubscribing",
            authFlowProps: {
              actionType: "Unsubscribe",
              entityTitle: `for email notifications from "${entityName}"`,
              entityAction: "action",
            },
          }),
        },
        pageType !== "homePage" && {
          query: "follow",
          loggingInTitle: `Logging you in so you can follow "${entityName}"`,
          modal: true,
          renderContent: renderActionContent({
            title: `Would you like to follow "${entityName}"?`,
            icon: faFollow,
            onConfirm: handleAction(followEntity),
            confirmButtonText: "Follow",
            successMessage: `Successfully followed "${entityName}".`,
            loadingLabel: "Following",
            authFlowProps: {
              actionType: "Follow",
              entityTitle: `"${entityName}"`,
              entityAction: "action",
            },
          }),
        },
        pageType !== "homePage" && {
          query: "unfollow",
          loggingInTitle: `Logging you in so you can unfollow "${entityName}"`,
          modal: true,
          renderContent: renderActionContent({
            title: `Would you like to stop following "${entityName}"?`,
            icon: faFollow,
            onConfirm: handleAction(unfollowEntity),
            confirmButtonText: "Stop following",
            successMessage: `Successfully unfollowed "${entityName}"`,
            loadingLabel: "Unfollowing",
            authFlowProps: {
              actionType: "Unfollow",
              entityTitle: `"${entityName}"`,
              entityAction: "action",
            },
          }),
        },
        (entity_type === "podcast" || entity_type === "episode") && {
          query: "bookmark",
          loggingInTitle: `Logging you in so you can bookmark ${
            entity_type === "episode" ? "this episode" : `"${entityName}"`
          }`,
          modal: true,
          renderContent: renderActionContent({
            title:
              entity_type === "episode"
                ? `Would you like to bookmark this episode${
                    podcast ? ` of "${getPodcastName(podcast)}"` : ""
                  }?`
                : `Would you like to bookmark "${entityName}"?`,
            icon: faBookmark,
            onConfirm: handleAction(setBookmarked, { bookmark: true }),
            confirmButtonText: "Bookmark",
            successMessage: `Successfully bookmarked ${
              entity_type === "episode" ? "this episode" : `"${entityName}"`
            }`,
            loadingLabel: "bookmarking",
            authFlowProps: {
              actionType: "Bookmark",
              entityTitle: `"${entityName}"`,
              entityAction: "action",
            },
          }),
        },
        (entity_type === "podcast" || entity_type === "episode") && {
          query: "unbookmark",
          loggingInTitle: `Logging you in so you can remove ${
            entity_type === "episode" ? "this episode" : `"${entityName}"`
          } from your bookmarks`,
          modal: true,
          renderContent: renderActionContent({
            title:
              entity_type === "episode"
                ? `Would you like to remove this episode${
                    podcast ? ` of "${getPodcastName(podcast)}"` : ""
                  } from your bookmarks?`
                : `Would you like to remove "${entityName}" from your bookmarks?`,
            icon: faBookmark,
            onConfirm: handleAction(setBookmarked, { bookmark: false }),
            confirmButtonText: "Remove bookmark",
            successMessage: `Successfully removed ${
              entity_type === "episode" ? "this episode" : `"${entityName}"`
            } from your bookmarks`,
            loadingLabel: "Removing bookmark",
            authFlowProps: {
              actionType: "Remove Bookmark for",
              entityTitle: `"${entityName}"`,
              entityAction: "action",
            },
          }),
        },
        (entity_type === "podcast" || entity_type === "episode") && {
          query: "review",
          loggingInTitle: `Logging you in so you can leave your review for "${entityName}"`,
          handler: () =>
            openRatingModal({
              entity_id,
              entity_type,
              initialRating: rating,
              initialReview: review,
              analyticsVariables: {
                source: source || requestContext.referrer,
              },
            }),
          clearQueries: ["rating", "review", "source"],
          analyticsParams: {
            source: source || requestContext.referrer,
          },
        },
        entity_type === "podcast" && {
          query: "claim",
          loggingInTitle: `Logging you in so you can claim "${entityName}"`,
          handler: () =>
            openClaimModal(entity.get("id"), {
              entity,
              analyticsVariables: {
                triggered_by_source: "actionHandler",
              },
            }),
        },
        entity_type === "episode" && {
          query: "play",
          clearQueries: ["time"],
          handler: () => {
            if (time) {
              const seconds = getSecondsDurationFromTimestamp(time);
              const audioElement = new Audio(entity.get("audio_url"));
              audioElement.addEventListener("loadeddata", () => {
                const { duration } = audioElement;
                // base on the algorithm above, if invalid time is passed then seconds would equate to 0
                if (seconds > 0 && seconds < duration) {
                  playerPlayAt(
                    entity.get("id"),
                    entity.get("audio_url"),
                    seconds
                  );
                  renderModal(time);
                }
              });
            }
          },
        },
        entity_type === "episode" && {
          query: "play",
          clearQueries: ["time"],
          handler: () => {
            if (time) {
              const seconds = getSecondsDurationFromTimestamp(time);
              const audioElement = new Audio(entity.get("audio_url"));
              audioElement.addEventListener("loadeddata", () => {
                const { duration } = audioElement;
                // base on the algorithm above, if invalid time is passed then seconds would equate to 0
                if (seconds > 0 && seconds < duration) {
                  seekPlayerTo(
                    entity.get("id"),
                    entity.get("audio_url"),
                    seconds
                  );
                  renderModal(time);
                }
              });
            }
          },
        },
        entity_type === "creator" && {
          query: "verify",
          loggingInTitle: "Logging you in so you can verify your profile",
          showModalName: "verifyCreator",
          showModalProps: { creator: entity },
        },
      ].filter((action) => !!action),
    [
      pageType,
      entityName,
      entity_type,
      entity_id,
      renderActionContent,
      isFollowing,
      handleAction,
      subscribeEntity,
      unsubscribeEntity,
      followEntity,
      unfollowEntity,
      podcast,
      setBookmarked,
      source,
      requestContext.referrer,
      entity,
      renderProLeadForm,
      openRatingModal,
      rating,
      review,
      openClaimModal,
      time,
      renderModal,
      seekPlayerTo,
    ]
  );

  return <ActionHandler actions={actions} />;
};

EntityPageActionHandler.propTypes = {
  getName: PropTypes.func,
  entity_type: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
};

EntityPageActionHandler.defaultProps = {
  entity: null,
};

export default memo(EntityPageActionHandler);
